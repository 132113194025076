import React from 'react'

import './AdminSection.scss'

const AdminSection = ({title, children}) => {
    return <section className="admin-section">
        <header className='admin-header-clickable' onClick={(event) => {
            event.target.classList.toggle("active");
            var content = event.target.nextElementSibling;
            if (content.style.maxHeight){
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        }}>
            <span onClick={(event) => {
                var content = event.target.parentElement.nextElementSibling;
                if (content.style.maxHeight){
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
            }}>{title}</span>
        </header>
        <div className="admin-section-content">
            {children}
        </div>
    </section>
}

export default AdminSection